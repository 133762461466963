@import '@aurora/shared-client/styles/_variables.pcss';

.lia-zoom {
  &-root {
    background-color: var(--lia-bs-gray-300);
    border-bottom: 1px solid var(--lia-bs-gray-300);
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: auto;
  }

  &-event {
    display: flex;
    height: 100%;
    position: relative;
  }

  &-refresh {
    color: var(--lia-bs-white);
    text-decoration: underline;
    padding: 0;
    &:hover {
      color: var(--lia-bs-white);
    }
  }

  &-refresh-info {
    color: var(--lia-bs-white);
    margin-bottom: 0;
    font-size: 16px;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(
      var(--lia-bs-black-h),
      var(--lia-bs-black-s),
      var(--lia-bs-black-l),
      0.8
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px 5px 0 0;
  }

  &-image {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }

  &-icon {
    fill: var(--lia-bs-white);
    width: 90px;
    height: 90px;
  }

  &-iframe {
    height: 100%;
    border: none;
    border-radius: 5px 5px 0 0;
  }

  &-meeting-ended {
    color: var(--lia-bs-white);
    margin-bottom: 0;
    font-size: 14px;
  }
}
